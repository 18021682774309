// import reportWebVitals from './reportWebVitals';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { useHistory } from 'react-router-dom';
import Loader from "react-loader-spinner";
import store from 'store2';
import PrivateContainer from './containers/PrivateContainer';

// Single Sign On Authentication
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './MsalConfig/authConfig';
import { MsalProvider } from "@azure/msal-react";
import { CustomNavigationClient } from './MsalConfig/NavigationClient';

export const msalInstance = new PublicClientApplication(msalConfig);

export default function App(props) {
	const { msalInstance } = props;
	const history = useHistory();
	const navigationClient = new CustomNavigationClient(history);
	msalInstance.setNavigationClient(navigationClient);

	return <div className="container-fluid">
		<MsalProvider instance={msalInstance}>
			<PrivateContainer />
			<div className="page-overlay"></div>
			<Loader type="Bars" className="page-loader" />
		</MsalProvider>
	</div>
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App msalInstance={msalInstance} />);

// ReactDOM.render(<App msalInstance={msalInstance} />, document.getElementById('root'));

console.log('VERSION: ' + process.env.VERSION);
store.set('VERSION', process.env.VERSION);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
