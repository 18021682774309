import React from 'react';

class Footer extends React.Component {
    constructor(props) {
        super(props);

        this.copyrightDate = new Date().getFullYear();
    }
    render() {
        return (
            <footer
                className="app-footer"
            >
                &copy; <a href="http://www.corlytics.com" tabIndex="-1">Corlytics</a> {this.copyrightDate}</footer>
        )
    }
}
export default Footer;