import React from 'react';

class ToggleMenu extends React.Component {
    hideMenu() {
        document.body.classList.add('page-menu-hidden');
    }

    showMenu(e) {
        if (e && e.target.classList.contains('page-menu-toggle')) return;
        document.body.classList.remove('page-menu-hidden');
    }

    render() {
        return (
            <div className="page-menu-toggle" onClick={this.hideMenu}>
                <i className="fa fa-angle-left"></i>
            </div>
        )
    }
}
export default ToggleMenu;