export const BreadcrumbMappings = [
    {
        route: '/regulators',
        items: [
            { url: '', name: 'Regulators' }
        ]
    },
    {
        route: '/obligation-terms',
        items: [
            { url: '', name: 'Manage Obligation Search Terms' }
        ]
    },
    {
        route: '/documents',
        items: [
            { url: '', name: 'Documents' }
        ]
    },
    {
        route: '/regulators/create',
        items: [
            { url: 'regulators', name: 'Regulators' },
            { url: '', name: 'Create Regulator' },
        ]
    },
    {
        route: '/regulators/update/:id',
        items: [
            { url: 'regulators', name: 'Regulators' },
            { url: '', name: 'Update Regulator' },
        ]
    },
    {
        route: '/regulators/details/:id',
        items: [
            { url: 'regulators', name: 'Regulators' },
            { url: '', name: 'Regulator Details' },
        ]
    },
    {
        route: '/reg-library/bulk-add-regulations',
        items: [
            { url: '', name: 'Bulk add regulations' },
        ]
    },
    {
        route: '/reg-library/metadata',
        items: [
            { url: '', name: 'Metadata' }
        ]
    },
    {
        route: '/reg-library/metadata/:id',
        items: [
            { url: 'reg-library/metadata', name: 'Metadata' },
            { url: '', name: 'Update Metadata' }
        ]
    },
    {
        route: '/source/details/:name/:id',
        items: [
            { url: 'regulators', name: 'Regulators' },
            { url: '', name: 'Source Details' },
        ]
    },
    {
        route: '/bot-health',
        items: [
            { url: '', name: 'BOT Health' }
        ]
    },
    {
        route: '/client-usage',
        items: [
            { url: '', name: 'Client Usage' }
        ]
    },
    {
        route: '/usage-report',
        items: [
            { url: '', name: 'Client Usage by Feature' }
        ]
    },
    {
        route: '/content-providers',
        items: [
            { url: '', name: 'Content Providers' }
        ]
    },
    {
        route: '/content-providers/:id',
        items: [
            { url: 'content-providers', name: 'Content Providers' },
            { url: '', name: 'Update Content Provider' },
        ]
    },
    {
        route: '/api-viewer/authorities',
        items: [
            { url: '', name: 'Authorities' },
        ]
    },
    {
        route: '/api-viewer/regulations/:id/:title',
        items: [
            { url: 'api-viewer/authorities', name: 'Authorities' },
            { url: '', name: 'Regulations' },
        ]
    },
    {
        route: '/api-viewer/versions/:id/:title',
        items: [
            { url: 'api-viewer/authorities', name: 'Api Viewer - Authorities' },
            { url: '', name: 'Api Viewer - Versions' },
        ]
    },
    {
        route: '/api-viewer/content/:id',
        items: [
            { url: 'api-viewer/authorities', name: 'Api Viewer - Authorities' },
            { url: '', name: 'Api Viewer - Content Editor' },
        ]
    },
    {
        route: '/taxonomy-manager/step-1/:clientId/:mapId/:clientName',
        items: [
            { url: 'taxonomy-manager', name: 'Select Client' },
            { url: '', name: 'Taxonomy Manager - Map Details' },
        ]
    },
    {
        route: '/taxonomy-manager/step-2/:clientId/:mapId/:clientName',
        items: [
            { url: 'taxonomy-manager', name: 'Select Client' },
            { url: '', name: 'Taxonomy Manager - Documents Mapping' },
        ]
    },
    {
        route: '/taxonomy-manager/step-3/:clientId/:mapId/:clientName',
        items: [
            { url: 'taxonomy-manager', name: 'Select Client' },
            { url: '', name: 'Taxonomy Manager - Category Structure' },
        ]
    },
    {
        route: '/taxonomy-manager/step-4/:clientId/:mapId/:clientName',
        items: [
            { url: 'taxonomy-manager', name: 'Select Client' },
            { url: '', name: 'Taxonomy Manager - Category Mapping' },
        ]
    },
    {
        route: '/taxonomy-manager/step-5/:clientId/:mapId/:clientName',
        items: [
            { url: 'taxonomy-manager', name: 'Select Client' },
            { url: '', name: 'Taxonomy Manager - Service Line Structure' },
        ]
    },
    {
        route: '/taxonomy-manager/step-6/:clientId/:mapId/:clientName',
        items: [
            { url: 'taxonomy-manager', name: 'Select Client' },
            { url: '', name: 'Taxonomy Manager - Service Line Mapping' },
        ]
    },
    {
        route: '/taxonomy-manager/step-7/:clientId/:mapId/:clientName',
        items: [
            { url: 'taxonomy-manager', name: 'Select Client' },
            { url: '', name: 'Taxonomy Manager - User Mapping' },
        ]
    },
    {
        route: '/taxonomy-manager/client-maps/:id/:name',
        items: [
            { url: 'taxonomy-manager', name: 'Select Client' },
            { url: '', name: 'Taxonomy Manager - Client Maps' },
        ]
    },
    {
        route: '/taxonomy-manager',
        items: [
            { url: '', name: 'Taxonomy Manager - Select Client' },
        ]
    },
    {
        route: '/classification-models/:id',
        items: [
            { url: 'classification-models', name: 'Classfication Models List' },
            { url: '', name: 'Classification Model Details' },
        ]
    },
    {
        route: '/classification-models',
        items: [
            { url: '', name: 'Classfication Models' }
        ]
    },
    {
        route: '/models-dashboard',
        items: [
            { url: '', name: 'Classfication Models Dashboard' }
        ]
    },
    {
        route: '/corlytics-updates/:id',
        items: [
            { url: 'corlytics-updates', name: 'Corlytics Updates' },
            { url: '', name: 'Notice Details' },
        ]
    },
    {
        route: '/corlytics-updates',
        items: [
            { url: '', name: 'Corlytics Updates' },
        ]
    },
    {
        route: '/workflow-config/:id/:clientId',
        items: [
            { url: 'workflow-config', name: 'Workflows List' },
            { url: '', name: 'Workflow Details' },
        ]
    },
    {
        route: '/workflow-config',
        items: [
            { url: '', name: 'Workflows List' },
        ]
    },
    {
        route: '/client-taxonomies/:id/:name',
        items: [
            { url: 'client-taxonomies', name: 'Client Taxonomies List' },
            { url: '', name: 'Client Taxonomy Details' },
        ]
    },
    {
        route: '/client-taxonomies',
        items: [
            { url: '', name: 'Client Taxonomies List' },
        ]
    },
    {
        route: '/themes/:id/:name',
        items: [
            { url: 'themes', name: 'Themes List' },
            { url: '', name: 'Theme Details' },
        ]
    },
    {
        route: '/themes',
        items: [
            { url: '', name: 'Themes List' },
        ]
    },
    {
        route: '/regulation-access-group/:id',
        items: [
            { url: 'regulation-access-group', name: 'Regulation Access Group List' },
            { url: '', name: 'Regulation Access Group Details' },
        ]
    },
    {
        route: '/regulation-access-group',
        items: [
            { url: '', name: 'Regulation Access Group List' },
        ]
    },
    {
        route: '/push-api/:id',
        items: [
            { url: 'push-api', name: 'Push Api List' },
            { url: '', name: 'Push Api Details' },
        ]
    },
    {
        route: '/push-api',
        items: [
            { url: '', name: 'Push Api List' },
        ]
    },
    {
        route: '/dashboards/:id',
        items: [
            { url: 'dashboards', name: 'Dashboards List' },
            { url: '', name: 'Dashboard Details' },
        ]
    },
    {
        route: '/dashboards',
        items: [
            { url: '', name: 'Dashboards List' },
        ]
    },
    {
        route: '/workflow-routing',
        items: [
            { url: '', name: 'Workflow Routing' },
        ]
    },
    {
        route: '/workflow-routing/:id/:clientId',
        items: [
            { url: 'workflow-routing', name: 'Workflow Routing' },
            { url: '', name: 'Rule Details' }
        ]
    },
    {
        route: '/auto-task',
        items: [
            { url: '', name: 'Tasks List' },
        ]
    },
    {
        route: '/auto-task/:id',
        items: [
            { url: 'auto-task', name: 'Tasks List' },
            { url: '', name: 'Task Details' }
        ]
    },
    {
        route: '/user-groups',
        items: [
            { url: '', name: 'User Groups List' },
        ]
    },
    {
        route: '/user-groups/:id/:clientId',
        items: [
            { url: 'user-groups', name: 'User Groups List' },
            { url: '', name: 'Group Details' }
        ]
    },
    {
        route: '/legal-entities',
        items: [
            { url: '', name: 'Legal Entities List' },
        ]
    },
    {
        route: '/procedures-config',
        items: [
            { url: '', name: 'Procedures Config' },
        ]
    },
    {
        route: '/regulators-export',
        items: [
            { url: '', name: 'Regulators Export' },
        ]
    },
    {
        route: '/report-logs',
        items: [
            { url: '', name: 'Report Logs' },
        ]
    },
    {
        route: '/report-statistics',
        items: [
            { url: '', name: 'Report Statistics' },
        ]
    }
]