import React from 'react';
import { Menu, Dropdown } from 'antd';

class GlobalSettings extends React.Component {
    render() {
        const menu = <Menu>
            <Menu.Item key="0">
                <div onClick={this.props.logout}>
                    <i className="fa fa-power-off mr-2"></i>
                    <span>Logout</span>
                </div>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="1" disabled>
                <div>
                    <i className="fa fa-user mr-2"></i>
                    <span>Preferences</span>
                </div>
            </Menu.Item>
        </Menu>

        return (
            <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
                <i className="fa fa-cog global-settings-icon ant-dropdown-link"></i>
            </Dropdown>
        )
    }
}
export default GlobalSettings;