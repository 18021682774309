import React from 'react';
import AuthService from '@services/Auth/AuthService';
import GlobalSettings from './GlobalSettings';

class Header extends React.Component {
    constructor(props) {
        super(props);

        this.onLogout = this.onLogout.bind(this);
    }

    onLogout() {
        return AuthService.msalLogoutUser();
    }

    render() {
        return (
            <header className="app-header">
                <div className="row">
                    <div className="col">
                        <a href="https://www.corlytics.com" className="page-logo">
                            <img src="/img/logo.png" alt="Corlytics" />
                        </a>
                        <span className="app-subtitle-divider"></span>
                        <span className="app-subtitle">Content Workbench</span>
                    </div>
                    <div className="col text-right">
                        <GlobalSettings logout={this.onLogout} />
                    </div>
                </div>
            </header>
        )
    }
}
export default Header;