import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { EventType } from "@azure/msal-browser";
import store from 'store2';
import Header from './Header';
import Footer from './Footer';
import Menu from './Menu';
import PageBody from './PageBody';
import AuthService from '@services/Auth/AuthService';
import moment from 'moment-timezone';

import '@style/private.scss';

// Single Sign On Authentication
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
// import { EventType } from "@azure/msal-browser";
import { loginRequest } from '../../MsalConfig/authConfig';

const queryString = require('query-string');

function setRedirectUri(uri) {
    const qString = queryString.parse(uri);

    if (!qString[window.location.origin + '/#state']) return;

    const parts = qString[window.location.origin + '/#state'].split('|');
    if (!parts.length || parts.length < 2) return;

    const partsDict = JSON.parse(parts[1]);

    let url = '';

    for (let i in partsDict.params) {
        if (!i || !i.length) continue;

        url += (!url.length ? '?' : '&') + i + '=' + partsDict.params[i];
    }
    store.set('RedirectURL', partsDict.url + url);
}

export default function PrivateContainer() {
    const [initialSettings, setInitialSettings] = useState(false);
    const [initialSettingsRequest, setInitialSettingsRequest] = useState(false);

    const { instance } = useMsal();
    const { accounts } = useMsal();
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    let redirected = useRef(false);


    setRedirectUri(window.location.href);

    AuthService.setMsalData(instance, accounts);

    const userAccessSub = AuthService.userAccessInitial.subscribe(resp => {
        const roles = AuthService.getUserRoles();
        if (!roles.includes('CONTENT-WBENCH')) AuthService.msalLogoutUser();
        else setInitialSettings(true);
    });


    useEffect(() => {
        if (timezone) moment.tz.setDefault(timezone);
        return () => {
            userAccessSub.unsubscribe();
        }
    });

    useEffect(() => {
        const failedEvents = [
            EventType.LOGIN_FAILURE,
            EventType.ACQUIRE_TOKEN_FAILURE,
            EventType.SSO_SILENT_FAILURE,
            EventType.ACQUIRE_TOKEN_BY_CODE_FAILURE,
            EventType.LOGOUT_FAILURE
        ];

        instance.addEventCallback((event) => {
            if (redirected.current) clearTimeout(redirected.current);

            if (event.eventType === EventType.HANDLE_REDIRECT_END) {
                redirected.current = setTimeout(() => {
                    instance.loginRedirect(loginRequest);
                }, 1000);
            }
            else {
                if (failedEvents.includes(event.eventType) || !!event.error) {
                    setTimeout(() => {
                        instance.loginRedirect(loginRequest);
                    }, 1000);
                }
            }
        }, error => {
            console.log('ERROR: ', error);
            setTimeout(() => {
                instance.loginRedirect(loginRequest);
            }, 1000);
        });
    }, []);

    const msalLoginRedirect = () => {
        instance.handleRedirectPromise().then(tokenResponse => {
            if (!tokenResponse) {
                if (accounts.length === 0) instance.loginRedirect(loginRequest)
            }
        }).catch(error => AuthService.msalLogoutUser());
        return null;
    }


    const initServices = () => {
        AuthService.initAxiosInterceptor();

        if (!initialSettings) {
            if (initialSettingsRequest) return;
            setInitialSettingsRequest(true);

            AuthService.getUserInitialSettings().then(resp => {
                AuthService.setUserInitialSettings(resp.data);
            }).catch(e => {
                setInitialSettingsRequest(false);
            });
        }

        const redirectUri = store.get('RedirectURL');

        if (redirectUri) {
            store.remove('RedirectURL');
            setTimeout(() => {
                window.location.href = window.location.origin + redirectUri;
            }, 1000);
        }

        AuthService.toggleSpinner(false);
    }

    return <div className="app-container private">
        <Router basename={process.env.PUBLIC_URL}>
            <UnauthenticatedTemplate>
                {/* {msalLoginRedirect()} */}
                {AuthService.toggleSpinner(true)}
                {/* <button className="btn btn-primary" onClick={() => instance.loginRedirect(loginRequest)}>Login</button> */}
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
                {AuthService.isLoggedIn() && initServices()}
                {initialSettings &&
                    <>
                        <Header />
                        <Menu />
                        <PageBody />
                        <Footer />
                    </>
                }
            </AuthenticatedTemplate>
        </Router>
    </div>
}