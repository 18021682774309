import React, { Fragment } from 'react';
import { Subscription } from 'rxjs';
import { Link, withRouter } from 'react-router-dom';
import MenuService from '@services/Menu/MenuService';

class Breadcrumb extends React.Component {
    constructor(props) {
        super(props);
        this.subscriptions = new Subscription();
        this.list = React.createRef();

        this.state = {
            breadcrumbs: [],
            activeBreadcrumg: [],
            visible: true
        }
    }

    componentDidMount() {
        this.getBreadcrumb();
    }

    componentWillUnmount() {
        this.subscriptions.unsubscribe();
    }

    getBreadcrumb() {
        this.setState(state => {
            state.breadcrumbs = MenuService.getMappedBreadcrumb();
            return state;
        });
    }

    getActiveBreadcrumbRoute(path) {
        if (process.env.PUBLIC_URL && process.env.PUBLIC_URL.length > 1) {
            path = path.replace(process.env.PUBLIC_URL, '');
        }
        const pathArray = path.split('/');
        const activeRoute = this.state.breadcrumbs.filter(item => {
            const itemsArray = item.route.split('/');

            if (pathArray.length !== itemsArray.length) return null;
            else {
                let isCorrectRoute = true;

                itemsArray.forEach((route, i) => {
                    if (route.charAt(0) !== ':' && pathArray[i] !== route) isCorrectRoute = false;
                });
                return isCorrectRoute;
            }
        });
        return activeRoute.length ? activeRoute[0].items : null;
    }

    getActiveBreadcrumb(path) {
        if (!this.state.breadcrumbs || path === '/') return null;
        else {
            const activeRoute = this.getActiveBreadcrumbRoute(path);
            if (!activeRoute) return null;

            return activeRoute.map((item, i) => {
                const last = i === activeRoute.length - 1;

                return <li key={i}>
                    <i className="fa fa-angle-right"></i>
                    {!last && <Link to={'/' + item.url}>{item.name}</Link>}
                    {last && item.name}
                </li>;
            });
        }
    }

    isApiViewer(path) {
        return path.includes('/api-viewer/') && !path.includes('/api-viewer/authorities');
    }

    render() {
        return (
            <Fragment>
                <div
                    className="app-breadcrumb"
                    style={{ display: this.isApiViewer(window.location.pathname) ? 'none' : 'block' }}
                >
                    <ul className="list-style-none">
                        <li><Link to="/">Home</Link></li>
                        {this.getActiveBreadcrumb(window.location.pathname)}
                    </ul>
                </div>
                <div
                    className="app-breadcrumb api-viewer"
                    style={{ display: this.isApiViewer(window.location.pathname) ? 'block' : 'none' }}
                >
                    <ul className="list-style-none"></ul>
                </div>

            </Fragment>
        )
    }
}
export default withRouter(Breadcrumb);