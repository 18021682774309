import Axios from 'axios-observable';

const Domain = process.env.REACT_APP_API_SERVER;

class ApiService {

    loginUser(username, password) {
        return Axios.post(Domain + '/auth/login', { username, password });
    }
}

export default new ApiService();