import { Observable } from 'rxjs';
import AuthService from '@services/Auth/AuthService';
import { BreadcrumbMappings } from './BreadcrumbMappings';
import store from 'store2';

class MenuService {
    // if menu is comming from backend please make a request and return promise otherwise return menu array
    menu = [
        {
            group: 'Content',
            items: [
                {
                    url: 'regulators',
                    title: 'Regulators',
                    icon: 'shield',
                    roles: ['ALL']
                },
                {
                    url: 'obligation-terms',
                    title: 'Obligation Terms',
                    icon: 'shield',
                    roles: ['ALL']
                },
                {
                    url: 'regulators-export',
                    title: 'Regulators Export',
                    roles: ['ALL']
                },
                {
                    url: 'documents',
                    title: 'Documents',
                    icon: 'shield',
                    roles: ['ALL']
                },
                {
                    url: 'bot-health',
                    title: 'BOT Health',
                    icon: 'medkit',
                    roles: ['ALL']
                }
            ]
        },
        {
            group: 'Regulations',
            items: [
                {
                    url: 'content-providers',
                    title: 'Content Providers',
                    icon: 'globe',
                    roles: ['ADMIN']
                },
                {
                    url: 'api-viewer/authorities',
                    title: 'Regulation Content Editor',
                    icon: 'eye',
                    roles: ['REG-PUBLISH']
                },
                {
                    url: 'regulation-access-group',
                    title: 'Regulation Access Group',
                    icon: 'object-group',
                    roles: ['ADMIN']
                }
            ]
        },
        {
            group: 'Regulation Library',
            items: [
                {
                    url: 'reg-library/bulk-add-regulations',
                    title: 'Bulk add regulations',
                    icon: 'eye',
                    roles: ['ADMIN']
                },
                {
                    url: 'reg-library/metadata',
                    title: 'Metadata',
                    icon: 'eye',
                    roles: ['REG-PUBLISH']
                }
            ]
        },
        {
            group: 'Content Automation',
            items: [
                {
                    url: 'classification-models',
                    title: 'Classification Models',
                    icon: 'th-large',
                    roles: ['MODEL-MAINT', 'MODEL-PROD']
                },
                {
                    url: 'models-dashboard',
                    title: 'Models Dashboard',
                    icon: 'eye',
                    roles: ['MODEL-MAINT', 'MODEL-PROD']
                }
            ]
        },
        {
            group: 'Taxonomy',
            items: [
                {
                    url: 'taxonomy-manager',
                    title: 'Taxonomy Manager',
                    icon: 'map-o',
                    roles: ['ADMIN']
                },
                {
                    url: 'client-taxonomies',
                    title: 'Client Taxonomies',
                    icon: 'server',
                    roles: ['TAXONOMY-EDIT']
                },
                {
                    url: 'themes',
                    title: 'Edit Themes',
                    icon: 'edit',
                    roles: ['THEMES-EDIT']
                },
                {
                    url: 'dashboards',
                    title: 'All Dashboards',
                    icon: 'window-restore',
                    roles: ['DASHBOARD-ADMIN']
                }
            ]
        },
        {
            group: 'Clients',
            items: [
                {
                    url: 'client-usage',
                    title: 'Client Usage',
                    icon: 'bar-chart',
                    roles: ['ADMIN']
                },
                {
                    url: 'usage-report',
                    title: 'Client Usage by Feature',
                    icon: 'list-alt',
                    roles: ['ADMIN']
                },
                {
                    url: 'corlytics-updates',
                    title: 'Corlytics Updates',
                    icon: 'envelope',
                    roles: ['ADMIN']
                }
            ]
        },
        {
            group: 'Client Management',
            items: [
                {
                    url: 'workflow-routing',
                    title: 'Workflow Routing',
                    roles: ['ADMIN']
                },
                {
                    url: 'workflow-config',
                    title: 'Workflow Config',
                    icon: 'cogs',
                    roles: ['ADMIN']
                },
                {
                    url: 'auto-task',
                    title: 'Task Automation',
                    roles: ['ADMIN']
                },
                {
                    url: 'user-groups',
                    title: 'User Groups',
                    roles: ['ADMIN']
                },
                {
                    url: 'legal-entities',
                    title: 'Legal Entities',
                    roles: ['ADMIN']
                },
                {
                    url: 'procedures-config',
                    title: 'Procedures Config',
                    roles: ['ADMIN']
                }
            ]
        },
        {
            group: 'Push Api',
            items: [
                {
                    url: 'push-api',
                    title: 'Push Api Dashboard',
                    icon: 'tachometer',
                    roles: ['PUSH-API-DASH']
                }
            ]
        },
        {
            group: 'Reporting System',
            items: [
                {
                    url: 'report-logs',
                    title: 'Report Logs',
                    icon: '',
                    roles: ['REPORTING-ADMIN', 'PUSH-API-DASH']
                },
                {
                    url: 'report-statistics',
                    title: 'Report Statistics',
                    icon: '',
                    roles: ['REPORTING-ADMIN', 'PUSH-API-DASH']
                }
            ]
        }
        // {
        //     group: 'Workflow',
        //     items: [
        //         {
        //             url: 'workflow-config',
        //             title: 'Workflow Config',
        //             icon: 'cogs',
        //             roles: ['COR-WFADMIN']
        //         }
        //     ]
        // }
    ]


    getMenuFromBackend() {
        // Make http request to get a menu list, prepare menu structure and return as observable
        return new Observable(subscriber => {
            subscriber.next('menu_from_the_backend');
        });
    }

    getMenuArray() {
        return new Observable(subscriber => {
            subscriber.next(this.menu);
        });
    }

    getMappedBreadcrumb() {
        return BreadcrumbMappings;
    }

    blockAccess(items) {
        let roles = [];

        items.forEach(item => {
            if (!item.roles) return;
            roles = [...roles, ...item.roles];
        });
        return this.checkRoles(roles);
    }

    checkAccess(menu, route) {
        let roles = [];

        this.menu.forEach(block => {
            block.items.forEach(item => {
                if (item.url === route) roles = item.roles;
            });
        });

        if (!roles.length) return false;

        let hasAccess = false;

        if (this.checkRoles(roles)) hasAccess = true;

        return hasAccess;
    }

    checkRoles(roles) {
        if (!roles) return false;

        const userRoles = store.get('roles');
        if (!userRoles) return false;

        let hasAccess = false;

        if (roles.includes('ALL')) hasAccess = true;
        else {
            roles.forEach(role => {
                if (userRoles.includes(role)) hasAccess = true;
            });
        }
        return hasAccess;
    }

    defineHomepage() {
        let homepage = null;

        this.menu.every(block => {
            block.items.every(item => {
                if (this.checkRoles(item.roles)) {
                    if (item.url !== 'documents') homepage = '/' + item.url;
                    return false;
                }
                return true;
            });
            if (homepage) return false;
            return true;
        });

        if (!homepage) return '/documents';
        return homepage;
    }
}
export default new MenuService();