import React, { Fragment } from 'react';
import { Routes } from '@router/router';
import Breadcrumb from '../Breadcrumb';

class PageBody extends React.Component {
    render() {
        return (
            <Fragment>
                <Breadcrumb />
                <main className="app-body m-3"><Routes /></main>
            </Fragment>
        )
    }
}
export default PageBody;