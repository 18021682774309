import React from 'react';
import { Subscription } from 'rxjs';
import { Link, withRouter } from 'react-router-dom';
import ToggleMenu from './ToggleMenu';
import AuthService from '@services/Auth/AuthService';
import MenuService from '@services/Menu/MenuService';

class Menu extends React.Component {
    constructor(props) {
        super(props);
        this.subscriptions = new Subscription();

        this.state = { menu: [] };

        this.toggleMenu = React.createRef();
        this.showMenu = this.showMenu.bind(this);
    }

    componentDidMount() {
        this.getMenu();
    }

    componentWillUnmount() {
        this.subscriptions.unsubscribe();
    }

    showMenu(e) {
        if (!this.toggleMenu.current) return 'Toggle menu not available';
        this.toggleMenu.current.showMenu(e);
    }

    getMenu() {
        this.subscriptions.add(
            MenuService.getMenuArray().subscribe(resp => {
                this.setState(state => {
                    state.menu = resp;
                    return state;
                });
            })
        );
    }

    isActive(itemUrl) {
        if (!itemUrl) return;

        const urlpath = itemUrl.split('/');
        let pathname = window.location.pathname.substring(1).split('/');
        return pathname[0] === urlpath[0];
    }

    render() {
        const menu = this.state.menu.map((section, idx) => {
            if (!section || !section.items) return null;

            const items = section.items.map((item, i) => {
                if (!MenuService.checkRoles(item.roles)) return null;
                return <li key={i}>
                    <Link
                        className={'page-menu-item' + (this.isActive(item.url) ? ' active' : '')}
                        to={'/' + item.url}
                    >
                        {/* <i className={'mr-2 fa fa-' + item.icon}></i> */}
                        <span>{item.title}</span>
                    </Link>
                </li>
            });

            if (!MenuService.blockAccess(section.items)) return null;

            return <div className="menu-section" key={'section-' + idx}>
                <hr />
                <div className="menu-section-title">{section.group}</div>
                <ul className="list-style-none">{items}</ul>
            </div>
        });

        return (
            <nav className="app-navigation" onClick={this.showMenu}>
                <div className="user-nolink">
                    {/* <i className="fa fa-user mr-2"></i> */}
                    <span>{AuthService.getUsername()}</span>
                </div>
                <ul className="list-style-none">{menu}</ul>
                <ToggleMenu ref={this.toggleMenu} />
            </nav>
        )
    }
}
export default withRouter(Menu);